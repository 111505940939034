import React from 'react'

import ServiciosComponent from 'components/serviciosComponent'
import BlockText from 'components/ui/block-text'

const ServiciosOperaciones = ({
  title,
  description,
  rawServicio,
  objetoServicio,
}) => {
  return (
    <div className="container">
      <div className="encabezado col-lg-10 col-12 mx-auto my-5 animate__animated  animate__zoomOut">
        <h2 className="encabezado-h2 col-10 text-center mx-auto">{title}</h2>
        <div className="col-10 text-center mx-auto">
          <BlockText data={description} />
        </div>
      </div>
      {rawServicio.map(({}, key) => (
        <ServiciosComponent
          imgPrincipal={
            objetoServicio[key]?.ImagenPrincipal?.image?.image?.asset?.url
          }
          altimgPrincipal={objetoServicio[key]?.ImagenPrincipal?.image?.alt}
          titleImgPrincipal={objetoServicio[key]?.ImagenPrincipal?.title}
          rawServicios={objetoServicio[key]?._rawDescription}
          objetoDescrition={objetoServicio[key]?.description}
          servicio={objetoServicio[key]}
        />
      ))}
    </div>
  )
}

export default ServiciosOperaciones
