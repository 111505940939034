import React, { useCallback } from 'react'

import Layout from 'components/layout'
import HeroComponent from 'components/hero'
import CallToAction from 'components/callToAction'
import ServiciosOperaciones from '../components/operacionMantenimiento'
import Seo from 'components/seo'

import { graphql } from 'gatsby'

const ApoyoSoportePage = ({ data, location }) => {
  const {
    sanityApoyoPage: {
      tabsOperacion: { seo, Hero, Call_to_Action, servicios },
    },
    sanityAppereance: {
      tabsAppereance: { header, footer },
    },
  } = data

  const setCallToActionBtn = useCallback(() => {
    console.log('click btn contáctanos')
  }, [])
  return (
    <Layout header={header} footer={footer}>
      {seo?.seo?.focus_keyword && seo?.author && seo?.seo?.focus_synonyms && (
        <Seo data={seo} />
      )}
      {Hero?.enable && (
        <HeroComponent
          navbar={Hero?.logoEmpresaHeader?.image?.asset?.url}
          navbarAlt={Hero?.logoEmpresaHeader?.alt}
          imageBackground={Hero?.image.image.asset.url}
          title={Hero?.title}
          subTitle={Hero?.description}
          btnText={Hero?.cta?.text}
          btnCallToAction={setCallToActionBtn}
          btnEnable={Hero?.cta?.enable}
          btnLink={Hero?.cta?.link}
          location={location.pathname}
        />
      )}
      {servicios?.enable && (
        <ServiciosOperaciones
          title={servicios?.titulo}
          description={servicios?._rawDescription}
          rawServicio={servicios?._rawServicio}
          objetoServicio={servicios?.servicio}
        />
      )}
      {Call_to_Action?.enable && (
        <CallToAction
          title={Call_to_Action?.title}
          paragraph={Call_to_Action?.description}
          btnText={Call_to_Action?.cta.text}
          btnEnable={Call_to_Action?.cta.enable}
          btnCallToAction={setCallToActionBtn}
        />
      )}
    </Layout>
  )
}

export const ApoyoQuery = graphql`
  query ApoyoQuery {
    sanityApoyoPage(slug: { current: { eq: "apoyo-y-soporte" } }) {
      tabsOperacion {
        seo {
          author
          _key
          seo {
            seo_title
            meta_description
            focus_synonyms
            focus_keyword
            _type
            _key
          }
          ogImage {
            asset {
              url
            }
          }
        }
        Hero {
          description
          enable
          title
          image {
            alt
            image {
              asset {
                url
              }
            }
          }
          cta {
            enable
            link
            text
          }
          logoEmpresaHeader {
            image {
              asset {
                url
              }
            }
            alt
          }
        }
        Call_to_Action {
          description
          enable
          title
          cta {
            enable
            link
            text
          }
        }
        servicios {
          enable
          _rawServicio
          _rawDescription
          titulo
          servicio {
            _rawGaleria
            _rawDescription
            description {
              _rawDescription
            }
            ImagenPrincipal {
              title
              image {
                alt
                image {
                  asset {
                    url
                  }
                }
              }
            }
            galeria {
              enable
              images {
                alt
                image {
                  asset {
                    url
                  }
                }
                _rawImage
              }
            }
          }
        }
      }
    }
    sanityAppereance(slug: { current: { eq: "apariencia" } }) {
      tabsAppereance {
        footer {
          correo1
          correo2
          correo3
          departamento
          direccion
          enableFooter
          footerParagrah
          footerTitle
          logoEmpresaFooter {
            alt
            image {
              asset {
                url
              }
            }
          }
        }
        header {
          correo
          enableHeader
          linkFacebook
          linkLinkedin
          linkInstagram
          linkTwitter
          messenger
          telefono
        }
      }
    }
  }
`

export default ApoyoSoportePage
