import React, { useEffect } from 'react'

import ImgPrincipal from 'components/componenteImagenes/ImagePrincipal'
import GrupoImagenesPrincipal from 'components/componenteImagenes/grupoImagenes'
import BlockText from 'components/ui/block-text'

const ServiciosComponent = ({
  imgPrincipal,
  altimgPrincipal,
  titleImgPrincipal,
  rawServicios,
  objetoDescrition,
  servicio,
}) => {
  useEffect(() => {
    console.log('objetoImagenes')
  })

  return (
    <div className="container">
      <div className="servicios">
        <div className="img-princ col-lg-10 mx-auto p-3">
          <ImgPrincipal
            imgPrincipal={imgPrincipal}
            alt={altimgPrincipal}
            title={titleImgPrincipal}
          />
        </div>
        <div className="items col-10 mx-auto d-flex flex-wrap">
          {rawServicios.map(({}, key) => (
            <div className="listadoServicios col-12 col-lg-4 my-3 px-4">
              <BlockText data={objetoDescrition[key]?._rawDescription} />
            </div>
          ))}
        </div>
        <div className="groupImages d-flex flex-wrap col-12">
          <GrupoImagenesPrincipal
            rawGaleria={servicio?._rawGaleria}
            rawImages={servicio?._rawGaleria?.images}
            objetoImagenes={servicio?.galeria}
            publicacion={servicio}
          />
        </div>
      </div>
    </div>
  )
}

export default ServiciosComponent
