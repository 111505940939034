import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

const ImgPrincipal = ({ imgPrincipal, title }) => {
  const [width, setWidth] = useState(null)
  const ref = useRef(null)
  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0)
  }, [ref.current])

  useEffect(() => {
    console.log('image', imgPrincipal)
  })

  return (
    <div className="col-12">
      <div
        className="imgPrincipal-img shadow "
        style={{
          backgroundImage: `url(${imgPrincipal})`,
          height: width / 1.7,
        }}
        ref={ref}
      ></div>
      <label className="imgPrincipal-label text-center py-3 ">{title}</label>
    </div>
  )
}

ImgPrincipal.propTypes = {
  imgPrincipal: PropTypes.string.isRequired,
}

export default ImgPrincipal
